.row {
  display: flex;
}

body  {width:100%;
  margin: 0;
  padding: 0;

}

.App {
  width: 100%;
  /* Remove any default margin or padding that could affect the layout */
  margin: 0;
  padding: 0;
}


#root {
  width: 100%;
  height: 100%;
  /* If you want to ensure that #root takes up the full viewport height */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(./images/unicornrescue.jpg);
  background-size: contain;
  background-repeat:no-repeat;
}

button { 
padding: 10px;;
max-width: 50vw;
border-radius: 5px;
opacity: 0.9;
background-color: #c2e8f3;
z-index: 2;
top: 10vw;
right:80vw;
} 

.startGame{
margin:5px;
border-radius: 5px;
}

.loadingContainer{
  display: none;
}

.gamecontainer {
  max-width: 900px;
  /* Remove any default margin or padding that could affect the layout */
  margin: 0 auto;
  padding: 0;
  /* Ensure that the gamecontainer is not larger than the viewport */
  box-sizing: border-box; /* Include padding and borders in the element's total width and height */
  max-height: 1000px;
  
}

#gameboard {
 margin: 0 auto;
 width: 96vw;
 max-width: 900px;
 display: grid;
 gap: 5px;
 grid-template-columns: repeat(5, 1fr);
 grid-template-rows: repeat(5, 1fr);
 grid-auto-flow: column;
 z-index: 1;
}


.gameover {
  position: relative;
  top:  0;
  left:  0;
  width:  100%;
  height: 50%;
  background-color: rgba(0,  0,  0,  0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:  10; /* Ensure it's above other elements */
  background: url(./images/gameover.jpg);
  min-height: 50vw;  
  background-size: contain;
  background-repeat:no-repeat;
  color: white;
}

.gameover h1{
   background: rgba(76, 175, 80, 0.6);
}

.gameinfo {
  position: relative;
  padding:25px;
  top:  0;
  left:  0;
  width:  95%;
  height: 50%;
  background-color: rgba(0,  0,  0,  0.5); /* Semi-transparent background */
  display: block;
  justify-content: center;
  align-items: center;
  z-index:  10; /* Ensure it's above other elements */
  background: url(./images/success.jpg);
  min-height: 50vw;  
  background-size: contain;
  background-repeat:no-repeat;
  color: white;
}

.gameinfo h2, h3{
  background: rgba(33, 49, 34, 0.6);
  display: block;
  box-sizing: border-box;
  width: 100%;
}
.gameinfo p {
  width: 50%;
  left:50%;
  position: relative;
  background: rgba(33, 49, 34, 0.6);
  display: block;
  box-sizing: border-box;
}




.card{
 background-color: lightgrey;
 color: #fff;
 border-radius: 5px;
 padding: 10px;
 font-size: 100%;
 text-align: left;
 aspect-ratio: 1/1;
 display: flex;
 max-width: 200px;
}


.controls{
  width: 100%;
  
}

.scorecard {
  margin: 0 auto;
  max-height: 10vw;
  position: relative;
  float: right;
  font-size: 0.9em;
  color: white;
  background: rgba(76, 175, 80, 0.6);
}
.difficulty {
  margin: 0 auto;
  max-height: 10vw;
  position: relative;
  float: right;
  font-size: 0.9em;
  color: white;
  background: rgba(76, 175, 80, 0.6);
  padding: 0px 10px;
}


.imgContainer{
  position: relative;
  width: 100%;
  height: 100%;
}

.forest{
  background: url(./images/forest-xl2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.forest-destroyed{
  background: url(./images/forest-destroyed.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.mountain{
  background: url(./images/wilderness-xl2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.mountain-destroyed{
  background: url(./images/mountain-destroyed.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.valley{
  background: url(./images/valley-xl3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.valley-destroyed{
  background: url(./images/valley-destroyed.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.river{
  background: url(./images/river-xl3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.river-destroyed{
  background: url(./images/river-destroyed.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.wilderness{
  background: url(./images/wilderness-xl3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.castle{
  background: url(./images/castle-xl3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.meadow{
  background: url(./images/meadow-xl5.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}



.knight, .dragon {
 width: 100%;
}
.knight, .unicorn, .dragon {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
 }

 .unicorn{
 width: 60%;
 left: 25%;
}

@media (min-width: 1200px) {
  .gamecontainer {
    top: 500px;
  }
}
